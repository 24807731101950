import React, { useContext } from 'react'
import './Hero.scss'
import FeelAndSale from '../FeelAndSale/FeelAndSale'
import IntroBar from '../IntroBar/IntroBar'
import { useStaticQuery, graphql } from 'gatsby'
import { HeaderContext } from '../Header/Header'

export default () => {
	const HeaderCTX = useContext(HeaderContext)
	const { wordpressPage, summerWordpressPage } = useStaticQuery(graphql`
		{
			wordpressPage(wordpress_id: { eq: 5 }) {
				id
				acf {
					title
					bigpicture {
						alt_text
						localFile {
							url
							childImageSharp {
								# Try editing the "maxWidth" value to generate resized images.
								fluid(maxWidth: 900, maxHeight: 600) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					bigvideo {
						video_bestand {
							source_url
						}
					}
					picture2 {
						alt_text
						localFile {
							url
							childImageSharp {
								# Try editing the "maxWidth" value to generate resized images.
								fluid(maxWidth: 600, maxHeight: 300) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
			summerWordpressPage: wordpressPage(wordpress_id: { eq: 563 }) {
				id
				acf {
					title
					bigpicture {
						alt_text
						localFile {
							url
							childImageSharp {
								# Try editing the "maxWidth" value to generate resized images.
								fluid(maxWidth: 900, maxHeight: 600) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					bigvideo {
						video_bestand {
							source_url
						}
					}
					picture2 {
						alt_text
						localFile {
							url
							childImageSharp {
								# Try editing the "maxWidth" value to generate resized images.
								fluid(maxWidth: 600, maxHeight: 300) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
	`)

	return (
		<div className="hero">
			<FeelAndSale
				content={
					HeaderCTX.winterTime ? wordpressPage : summerWordpressPage
				}
			/>
			<IntroBar />
		</div>
	)
}
