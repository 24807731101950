import React, { useContext } from 'react'
import './FeelAndSale.scss'
import Wave from '../../images/icons/Wave'
import Img from 'gatsby-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import Slider from 'react-slick'
import { encodeToReadableUrl } from '../../utils/encodeToReadableUrl'
import { HeaderContext } from '../Header/Header'

export default (props) => {
	const { content } = props
	const { bigpicture, picture2, bigvideo } = content.acf
	const HeaderCTX = useContext(HeaderContext)

	const { allWordpressWpActionTryout } = useStaticQuery(graphql`
		query {
			allWordpressWpActionTryout(
				filter: { acf: { tonen_op_homepage: { eq: true } } }
			) {
				nodes {
					id
					wordpress_id
					link
					acf {
						tonen_op_homepage
						link_to_existing
						title
						promotie_detail {
							amount
							description
							price
						}
					}
				}
			}
		}
	`)

	const tryOuts = allWordpressWpActionTryout?.nodes[0]?.acf
		? allWordpressWpActionTryout.nodes
		: []

	const SliderArrow = ({ className, to, onClick }) => (
		<button
			type="button"
			onClick={onClick}
			className={to === 'prev' ? 'slick-prev' : 'slick-next'}
			aria-label={to}
		>
			{to === 'prev' ? '←' : '→'}
		</button>
	)

	const carouselSettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		cssEase: 'cubic-bezier(.68,.63,.2,1.35)',
		arrows: tryOuts.length > 0,
		prevArrow: <SliderArrow to="prev" />,
		nextArrow: <SliderArrow to="next" />,
	}

	const bigPicture = bigpicture?.localFile?.childImageSharp?.fluid
	const secondPicture = picture2?.localFile?.childImageSharp?.fluid
	return (
		<div className="feel-and-sale">
			<div className="feel-and-sale__large">
				{bigvideo?.video_bestand?.source_url &&
				bigvideo.video_bestand.source_url !== 'null' ? (
					<video
						controls={false}
						preload={'auto'}
						autoPlay={true}
						loop={true}
						muted={true}
						src={
							bigvideo.video_bestand.source_url
								? bigvideo.video_bestand.source_url
								: bigvideo.video_bestand.src
						}
					/>
				) : bigPicture ? (
					<Img fluid={bigPicture} alt={bigpicture.alt_text} />
				) : (
					<img
						src={'images/inbalans-image-1.jpg'}
						alt={'In Balans Alkmaar'}
					/>
				)}
			</div>
			<div className="feel-and-sale__large">
				{tryOuts && (
					<Slider
						{...carouselSettings}
						className="feel-and-sale__small feel-and-sale__small--content"
					>
						{tryOuts.map((tryOut, i) => {
							let hasExistingLink = tryOut.acf.link_to_existing
							if (hasExistingLink) {
								hasExistingLink = hasExistingLink.replace(
									'https://www.inbalansalkmaar.nl',
									'',
								)
							}
							let str = tryOut.link
							str = encodeToReadableUrl(str)
							return (
								<Link
									key={`${str}-${i}`}
									to={
										hasExistingLink
											? hasExistingLink
											: `/actie/${str}/${tryOut.wordpress_id}`
									}
								>
									<h2>{tryOut.acf.title}</h2>
									<Wave winter={HeaderCTX.winterTime} />
									<div className={'list-content'}>
										<span>
											{
												tryOut?.acf?.promotie_detail
													?.amount
											}
											x
										</span>
										{ReactHtmlParser(
											tryOut?.acf?.promotie_detail
												?.description,
										)}
										<span>
											€
											{
												tryOut?.acf?.promotie_detail
													?.price
											}
										</span>
									</div>
								</Link>
							)
						})}
					</Slider>
				)}
				<div className="feel-and-sale__small">
					{secondPicture ? (
						<Img
							fluid={secondPicture}
							alt={secondPicture.alt_text}
						/>
					) : (
						<img src={'images/inbalans-image-2.jpg'} alt={''} />
					)}
				</div>
			</div>
		</div>
	)
}
