import React, { useContext } from 'react'
import './InfoBlockCourse.scss'
import Title from '../Title/Title'

import Specifications from '../Specifications/Specifications'
import Button from '../Button/Button'
import { SignUpFormContext } from '../SignUpFormModal/SignUpFormModal'
import Calendar from '../../images/icons/Calendar'
import Eur from '../../images/icons/Eur'
import TextInColumns from '../TextInColumns/TextInColumns'
import { HeaderContext } from '../Header/Header'
import { formatDate } from '../EventItems/EventItems'
import { Link } from 'gatsby'

const links = [
	{
		label: 'Nieuws',
		to: '/nieuws',
	},
	{
		label: 'Kalender',
		to: '/kalender',
	},
	{
		label: 'Materiaal & Onderhoud',
		to: '/materiaal-en-onderhoud/',
	},
	{
		label: 'Vacatures',
		to: '/vacatures/',
	},
	{
		label: 'Veelgestelde vragen',
		to: '/faq',
	},
	{
		label: 'Contact',
		to: '#contact',
	},
]

function hasNumber(myString) {
	return /\d/.test(myString)
}

export default ({ course, className }) => {
	const ctx = useContext(SignUpFormContext)
	const HeaderCTX = useContext(HeaderContext)

	const {
		title,
		information,
		course_description,
		specs,
		data,
		onlyText,
		homepage,
	} = course

	return (
		<div>
			<div
				className={`infoblock-course__holder${
					className ? ' ' + className : ''
				}`}
			>
				<article className={'infoblock-course__article'}>
					{typeof title !== 'undefined' && title.length ? (
						<Title heading={1} text={title} wave={true} />
					) : null}
					{typeof specs !== 'undefined' && specs.length ? (
						<Specifications specifications={specs} />
					) : null}
					<p>{course_description}</p>
				</article>
				{!homepage ? (
					<div className={'infoblock-course__cta'}>
						{typeof data !== 'undefined' ? (
							<footer className={'infoblock-course__info'}>
								{data.start_date ? (
									<div
										className={
											'event__list-item--meta-holder'
										}
									>
										<Calendar
											winter={HeaderCTX.winterTime}
										/>
										<div>
											<p
												className={
													'event__list-item--meta-title'
												}
											>
												START
											</p>
											<time
												dateTime={
													data.start_date !== 'null'
														? formatDate(
																data.start_date,
														  )
														: 'in overleg'
												}
											>
												{data.start_date !== 'null'
													? formatDate(
															data.start_date,
													  )
													: 'in overleg'}
											</time>
										</div>
									</div>
								) : null}
								{data.price ? (
									<div
										className={
											'event__list-item--meta-holder'
										}
									>
										<Eur />
										<div>
											<p
												className={
													'event__list-item--meta-title'
												}
											>
												PRIJS
											</p>
											<data
												className="money"
												value={
													data.price !== 'null'
														? data.price
														: 'in overleg'
												}
											>
												{data.price !== 'null'
													? data.price
													: 'in overleg'}
											</data>
										</div>
									</div>
								) : null}
							</footer>
						) : null}
						{!onlyText &&
						typeof data !== 'undefined' &&
						data.price &&
						hasNumber(data.price) ? (
							<Button
								text={'inschrijven cursus'}
								action={() => ctx.toggleModal()}
								className={
									'text-and-buttons__button button primary'
								}
							/>
						) : typeof data !== 'undefined' &&
						  data.price &&
						  !hasNumber(data.price) ? (
							<Button
								text="mail ons!"
								to={`mailto:info@inbalansalkmaar.nl?subject=${title}`}
							/>
						) : null}
					</div>
				) : (
					<div className="infoblock-course__cta infoblock-course__homepage">
						<h3>
							Meer over schaatsen, skeeleren en mountainbiken bij
							In Balans Alkmaar.
						</h3>
						<ul>
							{links.map((link) => (
								<li key={`${link.label}links`}>
									{!link.to.includes('/') ? (
										<a
											href={link.to}
											className="link__underline--primary"
										>
											{link.label}
										</a>
									) : (
										<Link
											to={link.to}
											className="link__underline--primary"
										>
											{link.label}
										</Link>
									)}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
			{typeof information !== 'undefined' ? (
				<TextInColumns content={information} />
			) : null}
		</div>
	)
}
